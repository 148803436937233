import bbc_logo from '../images/Logos/SW_Logo_Yellow.svg';
import client_logo from '../images/Logos/client_logo_white.svg';
import client_logo_dark from '../images/Logos/client_logo.svg';
import about_icon from '../images/Icons/about_icon_orange.svg';
import social_image from '../images/social.jpg'
import social_twitter from '../images/socialtwitter.jpg'

let liveDomain = "https://www.bbc.com"
let liveUrlBase = liveDomain+"/storyworks/specials/caring-with-courage" 

let GlobalData = {
    CDNPath:"https://d23jjs2sr4dibe.cloudfront.net",
    pageTitle:"Caring with Courage",
    pageDescription:"Discover the extraordinary stories of nurses in this new online series",
    keywords:"",
    liveURL:liveUrlBase,
    tracking:{
        gaID:"UA-72501420-1",
        gaCategory:"ICN 2022"
    },
    metaData:{
        title:"#Advertisement feature presented by International Council of Nurses",
        facebookDescription:"Discover the extraordinary stories of nurses in this new online series #CaringWithCourage",
        facebookSocialImage:liveDomain+social_image,
        twitterDescription:"#Advertisement feature presented by International Council of Nurses Discover the extraordinary stories of nurses in this new online series #CaringWithCourage",
        twitterSocialImage:liveDomain+social_twitter,
        urlPath:liveUrlBase+"/"
    },
    header:{
        bbc:{
            logoPath: bbc_logo,
            bbcURL: "http://www.bbc.co.uk/storyworks/",
            alt: "BBC Storyworks"
        },
        client:{
            logoPath: client_logo,
            logoPathDark: client_logo_dark,
            clientURL: "https://www.icn.ch/",
            alt: "Client logo",
            copy: "Advertisement Feature<br/>Presented By"
        },
        about:{
            logoPath: about_icon,
            aboutURL:"https://www.bbcglobalnews.com/bbc-storyworks-content-definitions/",
            alt: "About icon",
            copy:"About this"
        },
        menu:{
            menuCopy:"About",
            menuLink:"http://www.bbc.com/storyworks/caring-with-courage/about-nurses"
        }
    },
    footer:{
        copyright:{
            copy:"Copyright © 2022 BBC. The BBC is not responsible for the content of external sites.",
            readMore:" Read more",
            URL:"http://www.bbc.co.uk/help/web/links/"
        },
        cookies:{
            cookieCopy:"Cookies",
            cookieURL:"https://www.bbc.co.uk/usingthebbc/cookies/",
            adChoices:"Ad Choices/ Do Not Sell My Info",
            adChoicesURL: "https://www.bbc.com/usingthebbc/cookies/how-does-the-bbc-use-cookies-for-advertising/"
        }

    }
}

export default GlobalData;