// extracted by mini-css-extract-plugin
export var SubHero = "SubHero-module--SubHero--4OAEL";
export var SubHero__card = "SubHero-module--SubHero__card--BFVUT";
export var SubHero__card__copy = "SubHero-module--SubHero__card__copy--O7vlU";
export var SubHero__card__desc = "SubHero-module--SubHero__card__desc--M2Rp8";
export var SubHero__card__header = "SubHero-module--SubHero__card__header--32jNp";
export var SubHero__content = "SubHero-module--SubHero__content--u4pq1";
export var SubHero__wrapper = "SubHero-module--SubHero__wrapper--WcUnS";
export var menuHeightDT = "64px";
export var menuHeightMob = "39px";
export var menuHeightTab = "82px";
export var screenLg = "992px";
export var screenLgMax = "991px";
export var screenMd = "768px";
export var screenMdMax = "767px";
export var screenSm = "576px";
export var screenSmMax = "575px";
export var screenXl = "1200px";
export var screenXlMax = "1199px";