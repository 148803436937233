//////// Package Imports 
import React, {useEffect, useRef} from "react"
import {FacebookShareButton, LinkedinShareButton, TwitterShareButton} from 'react-share';
import {useMediaQuery} from 'react-responsive';

/////////Greensock imports
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import SplitText from "gsap/dist/SplitText";

//////// Component Imports 
import Footer from '../../Footer/Footer';

//////// Helper Imports 
import { getFileName } from '../../../helpers/FileCatching';
import useDocumentHeight from '../../../helpers/useDocumentHeight'
import { trackCustomEvent } from "../../../helpers/tracking"

//////// Styling Imports 
import * as classes from './Credits.module.scss';

// Data Imports
import GlobalData from '../../../data/global'

//////// Image Imports 
const logoImages = require.context('../../../images/Icons/', true, /.*\.(gif|png|jpe?g|svg)$/i);


function Credits({content, type, horizontalScrollWrapper}) {

    function onFacebookClick(){
        let trackObject = {
            category: GlobalData.tracking.gaCategory,
            action: "Click",
            label: "Social Sharing Facebook"
        }
        trackCustomEvent(trackObject);
    }

    function onTwitterClick(){
        let trackObject = {
            category: GlobalData.tracking.gaCategory,
            action: "Click",
            label: "Social Sharing Twitter"
        }
        trackCustomEvent(trackObject);
    }

    function onLinkedInClick(){
        let trackObject = {
            category: GlobalData.tracking.gaCategory,
            action: "Click",
            label: "Social Sharing Linkedin"
        }
        trackCustomEvent(trackObject);
    }
    
    gsap.registerPlugin(ScrollTrigger);
    gsap.registerPlugin(SplitText);

    let data = content.content;
    let social = GlobalData.metaData;
    let docHeight = useDocumentHeight();
    let contentContainer = useRef(null);
    let copy = useRef(null);
    let facebook = useRef(null);
    let twitter = useRef(null);
    let linkedin = useRef(null);

    let animationTimeline = useRef(null)
    let animationTimelineTrigger = useRef(null)

    const isDesktop = useMediaQuery({
        query: `(min-width: ${classes.screenLg})`
    })

    useEffect(() => {

        timeline()

        if(horizontalScrollWrapper){
            animationTimelineTrigger.current = ScrollTrigger.create({
                trigger: contentContainer.current,
                scroller:horizontalScrollWrapper.current,
                horizontal:true,
                onEnter:() => {animationTimeline.current.resume()},
                start:() => `left 60%`,
                end:() => `max`
            })
        }else{
            animationTimelineTrigger.current = ScrollTrigger.create({
                trigger: contentContainer.current,
                onEnter:() => {animationTimeline.current.resume()},
                start:() => `top-=${classes.menuHeightTab} 40%`,
                end:() => `top-=${classes.menuHeightTab} top`,
            })
        }
     }, [])
 
    function timeline(){
        animationTimeline.current = gsap.timeline();
        
        animationTimeline.current.from(copy.current, {
            autoAlpha: 0,
            duration: 2.5
        });
    
        animationTimeline.current.from(facebook.current, {
            autoAlpha: 0,
            duration:0.5
        }, "-=2");

        animationTimeline.current.from(twitter.current, {
            autoAlpha: 0,
            duration:0.5
        }, "-=1.5");

        animationTimeline.current.from(linkedin.current, {
            autoAlpha: 0,
            duration:0.5,
        },"-=1");
        
        animationTimeline.current.pause()
         
    }

    useEffect(() => {

    },[docHeight])

    return (
        <div className={`${classes.Credits}`} style={{backgroundColor: data.backgroundColor, color: data.textColor}}>
            <div ref={contentContainer} className={`${classes.Credits__content}`}>
                <div className={`${classes.Credits__title_container}`}>
                    <h1 ref={copy} className={`PalmerLake`} dangerouslySetInnerHTML={{__html:data.title}}></h1>
                    <div>
                        <ul>
                            <li ref={facebook} className={`${classes.Credits__social__list_item}`} onClick={() => onFacebookClick()}>
                                <FacebookShareButton quote={social.facebookDescription} url={`${social.urlPath}`}>
                                    <img alt="Facebook" src={getFileName(logoImages,"Facebook.svg").default}/>
                                </FacebookShareButton>
                            </li>
                            <li ref={twitter} className={`${classes.Credits__social__list_item}`} onClick={() => onTwitterClick()}>
                                <TwitterShareButton title={social.twitterDescription} url={`${social.urlPath}`}>
                                    <img alt="Twitter" src={getFileName(logoImages,"Twitter.svg").default}/>
                                </TwitterShareButton>
                            </li>
                            <li ref={linkedin} className={`${classes.Credits__social__list_item}`} onClick={() => onLinkedInClick()}>
                                <LinkedinShareButton url={"https://www.linkedin.com/shareArticle?mini=true&summary="+social.facebookDescription+"&source=LinkedIn&url="+social.urlPath}>
                                    <img alt="Linked In" src={getFileName(logoImages,"LinkedIn.svg").default}/>
                                </LinkedinShareButton>
                            </li>
                        </ul>
                    </div>
                </div>
                <Footer />
            </div>
           
        </div>
    )
}

export default Credits