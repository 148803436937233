//////// Package Imports 
import React from "react";
import MediaQuery from 'react-responsive';

//////// Helper Imports 
import { getFileName } from '../../helpers/FileCatching';

//////// Styling Imports 
import * as classes from './BackgroundImage.module.scss';

function BackgroundImage({backgroundContent, imagePath, backgroundPosition}) {
    return (
        
        <div className={`${classes.BackgroundImage} ${(backgroundPosition === 'right' ? classes.BackgroundImage__right: classes.BackgroundImage__left)}`} >
            <MediaQuery maxWidth={classes.screenMdMax}>
                <div className={`${classes.BackgroundImage__wrapper} backgroundImage__wrapper`} style={{backgroundImage: `url(${getFileName(imagePath,backgroundContent.mobile).default})`}}></div>
            </MediaQuery>
            <MediaQuery minWidth={classes.screenMd} maxWidth={classes.screenLgMax}>
                <div className={`${classes.BackgroundImage__wrapper} backgroundImage__wrapper`} style={{backgroundImage: `url(${getFileName(imagePath,backgroundContent.tablet).default})`}}></div>
            </MediaQuery>
            <MediaQuery minWidth={classes.screenLg}>
                <div className={`${classes.BackgroundImage__wrapper} backgroundImage__wrapper`} style={{backgroundImage: `url(${getFileName(imagePath,backgroundContent.desktop).default})`, backgroundPositionX: backgroundPosition}}></div>
            </MediaQuery>
        </div>
    )
}

export default BackgroundImage