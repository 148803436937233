import { useState } from 'react';

const useModal = () => {
  const [isOpen,setisOpen] = useState(false)

	const handleMenu = (e, v = null ) => {


    
    //menu burger is clicked
    if( e === "navbarmenu"){

      //get elements
      let body = document.querySelector('body');
      
      body.style.overflow === "" ? body.style.overflow = "hidden" : body.removeAttribute("style") //hide scroll when opens modal

    }

    
    //show / hide modal
		(!isOpen) ? setisOpen(true): setisOpen(false); 
  }


  
  return [
    isOpen,
    handleMenu
    
  ]
}


export default useModal;
