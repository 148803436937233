//////// Package Imports 
import React from "react"

//////// Component Imports 
import BackgroundImage from "../../../sharedComponents/BackgroundImage/BackgroundImage";
import Cards from "../../../sharedComponents/Cards/Cards"
import HorizontalPin from "../HorizontalScrollController/HorizontalPin";

//////// Styling Imports 
import * as classes from './Guides.module.scss';

//////// Image Imports 
const contentImages = require.context('../../../images/HeroImages/', true, /.*\.(gif|png|jpe?g|svg)$/i);

function Guides({content, horizontalScrollWrapper, leftPinIndex}) {
    
    let data = content.content;

    return (
        <div className={`${classes.Guides}`} style={{backgroundColor: data.backgroundColor, color: data.textColor}}>
            <div className={`${classes.Guides__title_container}`} style={{backgroundColor: data.backgroundColor}}>
                <BackgroundImage backgroundContent={data.backgroundImage} imagePath={contentImages} backgroundPosition={data.backgroundImagePosition} />
                <div className={`${classes.Guides__content}`}>
                    <h1 className={`NationalCondensed-black`} dangerouslySetInnerHTML={{__html:data.title}}></h1>
                </div>
            </div>
            <div className={`${classes.Guides__cards_container}`} style={{backgroundColor: data.backgroundColor}}>
                <Cards content={data} />
            </div>

        </div>
    )
}

export default Guides