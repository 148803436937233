// extracted by mini-css-extract-plugin
export var Articles = "Articles-module--Articles--TA716";
export var gameChangersColour = "#c7e9ec";
export var guidesColour = "#ffeed7";
export var pathFindersColour = "#e0b9e3";
export var screenLg = "992px";
export var screenLgMax = "991px";
export var screenMd = "768px";
export var screenMdMax = "767px";
export var screenSm = "576px";
export var screenSmMax = "575px";
export var screenXl = "1200px";
export var screenXlMax = "1199px";
export var trailBlazersColour = "#ed8969";