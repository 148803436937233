import { useState, useLayoutEffect } from 'react';

const useWindowSize = () => {
 
  const [size, setSize] = useState([0, 0, "mobile"]);
  
  useLayoutEffect(() => {

    const updateSize = () =>{
      let size = "mobile";
      if(window.innerWidth >= 576){
        size = "tablet";
      }
      if(window.innerWidth >= 992){
        size = "desktop";
      }
      setSize([window.innerWidth, window.innerHeight-69, size]);
    }

    window.addEventListener('resize', updateSize);
    
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;

}


export default useWindowSize;
