//////// Package Imports 
import React, {useEffect, useState} from "react"
import MediaQuery from 'react-responsive';
import { Helmet } from 'react-helmet';

/////// ICONS/LOGOS
import BBCSWLogo from '../../images/Logos/SW_Logo.inline.svg';
import AboutIcon from "../../images/Icons/about_icon.inline.svg"

//////// Helper Imports 
import { getFileName } from '../../helpers/FileCatching';
import { trackCustomEvent } from "../../helpers/tracking"
import useModal from "../../helpers/useModal";

//////// Styling Imports 
import * as classes from './Header.module.scss';

//////// Data Imports
import GlobalData from '../../data/global.js';

//////// Image Imports 
const logoImages = require.context('../../images/Icons/', true, /.*\.(gif|png|jpe?g|svg)$/i);

function onClientClick(){
    let trackObject = {
        category: GlobalData.tracking.gaCategory,
        action: "Click",
        label: "Client logo header"
    }
    trackCustomEvent(trackObject)
}

export default function Header({headerThemes, isSticky}) {
  let header = GlobalData.header;
  const [isOpen,handleMenu] = useModal(false)
  let [headerThemeClasses, setHeaderThemesClasses] = useState([])

  useEffect(() => {

    let themesList = headerThemes.map(theme => {
      return classes[`${theme}`]
    })

    setHeaderThemesClasses(themesList)

  }, [headerThemes])

  function headerMarkup(params) {

    function MenuItem() {
		
      return (
        <div className={`${classes.Header__MenuItem} ` + (isOpen ? `${classes.Header__MenuItem__menuOpen}` : ``)}>
          <div className={`${classes.Header__MenuItem__menuContent } ${classes.Header__MenuItem__menuContentOpen} ${classes.menu__option_list} `}>
            <a href={header.menu.menuLink} target="_blank" rel="noreferrer"  onClick={ ()  => handleMenu("navbarmenu")}>
              <p className={`CuriousSans-bold`}>{header.menu.menuCopy}</p>
            </a>
          </div>
        </div>
      )
    }

    return (
      <div className={` ${classes.Header} ${headerThemeClasses.join(" ")}`}>
          <div className={` ${classes.Header__left_container}`}>
            <div id="SW_logo_container" className={` ${classes.Header__left_container__logo_container}`}>
              <BBCSWLogo/>
            </div>
          </div>
      
          <div className={` ${classes.Header__right_container}`}>
              <div className = {`${classes.Header__right_container__menu_container}`} >			
                <a href={header.menu.menuLink} target="_blank" rel="noreferrer">
                  <p className={`CuriousSans-bold menu_item`}>{header.menu.menuCopy}</p>
                </a>
              </div>
              <div className={` ${classes.Header__right_container__advertising}`}>
                <div className={` ${classes.Header__right_container__client_logo_container}`}>
                  <div className={` ${classes.Header__right_container__client_logo_copy}`}>
                    <p dangerouslySetInnerHTML={{__html:header.client.copy}}></p>
                  </div>
                  <div className={` ${classes.Header__right_container__client_logo_wrapper}`}>
                    <a onClick={() => {onClientClick()}} href={header.client.clientURL} target="_blank" rel="noreferrer">
                      <img className={` ${classes.Header__right_container__client_logo} client_logo_light`} src={header.client.logoPath} alt={header.client.alt}/>
                      <img className={` ${classes.Header__right_container__client_logo} client_logo_dark`} src={header.client.logoPathDark} alt={header.client.alt}/>
                    </a>
                  </div>
                </div>
                <MediaQuery maxWidth={classes.screenSmMax}>
                  <div className = {`${classes.Header__hamburger} hamburger_container ` + (isOpen ? `${classes.Header__hamburgerOpen}` : ``)}
                    onClick = { () => handleMenu("navbarmenu")} >
                    <span className={  ` ${classes.Header__hamburger__lines} `}></span>
                    <span className={  ` ${classes.Header__hamburger__lines} `}></span>
                    <span className={  ` ${classes.Header__hamburger__lines} `}></span>										
                  </div>
                  { isOpen && <MenuItem/>}
                </MediaQuery>
                <a className={` ${classes.Header__right_container__about_container}`} href={header.about.aboutURL} target="_blank" rel="noreferrer">
                  <div className={`${classes.Header__right_container__about_wrapper} about_icon_container`}>
                    <div className={` ${classes.Header__right_container__about_icon_container}`}>
                      <MediaQuery maxWidth={classes.screenSmMax}>
                        <AboutIcon/>
                      </MediaQuery>
                      <MediaQuery minWidth={classes.screenSm}>
                        <AboutIcon/>
                      </MediaQuery>
                    </div>
                    <div className={` ${classes.Header__right_container__about_copy}`}>
                      <p dangerouslySetInnerHTML={{__html:header.about.copy}}></p>
                    </div>
                  </div>
                </a>
              </div>
          </div>
      </div>
    )
  }

  return (
    <>
      <Helmet>
        <title>{ GlobalData.pageTitle }</title>
        <meta name="keywords" content={GlobalData.keywords}/>
        <meta name="description" content={GlobalData.pageDescription} />
        <meta property="og:url" content={GlobalData.metaData.urlPath} />
        <meta property="og:title" content={GlobalData.metaData.title} />
        <meta property="og:description" content={GlobalData.metaData.socialDescription} />
        <meta property="og:image" content={GlobalData.metaData.facebookSocialImage} />
        <meta name="twitter:card" content="summary_large_image"/>
        <meta name="twitter:title" content={GlobalData.metaData.title} />
        <meta name="twitter:description" content={GlobalData.metaData.socialDescription}/>
        <meta name="twitter:image" content={GlobalData.metaData.twitterSocialImage} />
      </Helmet>
      {(isSticky
        ?
        <>
          <div className={` ${classes.Header__sticky_filler}`}>
            {headerMarkup()}
          </div>
          <div className={` ${classes.Header__sticky}`}>
          {headerMarkup()}
          </div>
        </>
        :
        ""
      )}


    </>
  )
}