//////// Package Imports 
import React, {useRef, useEffect} from "react";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import ScrollToPlugin from "gsap/ScrollToPlugin"
import SectionPins from "./SectionPins"
//////// Styling Imports 
import * as classes from './HorizontalScrollController.module.scss';


function HorizontalScrollContainer({children, pinItems, onSectionIndex}) {

    gsap.registerPlugin(ScrollTrigger);
    gsap.registerPlugin(ScrollToPlugin);

    let leftPinIndexCount = 0;
    let rightPinIndexCount = 5;
    
    let horizontalScrollPin = useRef(null);
    let horizontalScrollContainer = useRef(null);
    let backgroundScrollTrigger = useRef(null);
    let verticalScrollContainer = useRef(window);
    let horizontalScrollWrapper = useRef(null);
    let horizontalScrollContentWrapper = useRef(null);
    
    let horizontalScrollContainerOffset = useRef(null);
    let enableVerticalScrollListenerTimeout = useRef(null);
    let enableHorizontalScrollListenerTimeout = useRef(null);
    
    function isElement(element) {
        return React.isValidElement(element);
    }

    function isDOMTypeElement(element) {
        return isElement(element) && typeof element.type === 'string';
    }

    const childrenWithProps = React.Children.map(children, child => {
        // Checking isValidElement is the safe way and avoids a typescript
        // error too.
        
        if (isElement(child) && !isDOMTypeElement(child)) {
            
            let clonedElement = React.cloneElement(child, { horizontalScrollWrapper:horizontalScrollWrapper, leftPinIndex:leftPinIndexCount, rightPinIndex:rightPinIndexCount });
            rightPinIndexCount--
            leftPinIndexCount++
            return clonedElement

        }
        return child;
    });

    useEffect(() => {
        const cleanupInstances = {
            horizontalScrollWrapper:horizontalScrollWrapper,
            verticalScrollContainer:verticalScrollContainer,
            backgroundScrollTrigger:backgroundScrollTrigger
        }

        if(horizontalScrollWrapper.current){
            initPositions();
            initVerticalScrollListener();
            initHorizontalScrollListener()
            initScrollPin();
        }

        window.HORIZONTAL_SCROLL_WRAPPER = horizontalScrollWrapper.current

        return () => {
            
            onUnmount(cleanupInstances)

        }

    }, [])
    //eslint-disable-next-line react-hooks/exhaustive-deps

    function onUnmount(cleanupInstances){
        if(cleanupInstances.horizontalScrollWrapper.current)
            cleanupInstances.horizontalScrollWrapper.current.onscroll = null
        
        cleanupInstances.verticalScrollContainer.current.onscroll = null

        if(cleanupInstances.horizontalScrollWrapper.current)
            cleanupInstances.backgroundScrollTrigger.current.kill()

        clearTimeout(enableHorizontalScrollListenerTimeout.current)
        clearTimeout(enableVerticalScrollListenerTimeout.current)
    }

    function initScrollPin(){
        backgroundScrollTrigger.current = ScrollTrigger.create({
            trigger: horizontalScrollContainer.current,
            pin: horizontalScrollPin.current,
            start:`top ${classes.menuHeightDT}`,
            onEnter:()=>{onSectionIndex(0)},
            onLeaveBack:()=>{onSectionIndex(0)},
            onRefresh:() => {initPositions();onVerticalScroll();},
            end:() => `${(horizontalScrollContentWrapper.current ? horizontalScrollContentWrapper.current.clientWidth : 0)}px`,
        })
    }

    function initPositions(){
        if(horizontalScrollContainer.current){
            var h = document.documentElement, 
                b = document.body
        
            var bodyRect = (h.getBoundingClientRect() || b.getBoundingClientRect()),
            elemRect = horizontalScrollContainer.current.getBoundingClientRect()
            horizontalScrollContainerOffset.current =  ( elemRect.top-parseInt(classes.menuHeightDT,10) ) - bodyRect.top;
        }

    }

    function onVerticalScroll(e){

        if(horizontalScrollWrapper.current){

            horizontalScrollWrapper.current.onscroll = null
        
            if(enableHorizontalScrollListenerTimeout.current){
                clearTimeout(enableHorizontalScrollListenerTimeout.current)
            }
        
            enableHorizontalScrollListenerTimeout.current = setTimeout(function(){
            initHorizontalScrollListener()
            }, 200)

            var h = document.documentElement, 
            b = document.body,
            st = 'scrollTop',
            sh = 'scrollHeight';
        
        
            var percent = ((h[st]||b[st])-horizontalScrollContainerOffset.current) / (((h[sh]||b[sh]) - h.clientHeight) - horizontalScrollContainerOffset.current) * 100;

            horizontalScrollWrapper.current.scrollTo((percent/100)*(horizontalScrollWrapper.current.scrollWidth-horizontalScrollWrapper.current.clientWidth),0);

        }

    }

    function initVerticalScrollListener(){
  
            verticalScrollContainer.current.onscroll = onVerticalScroll
        
    }

    function initHorizontalScrollListener(){
        
        horizontalScrollWrapper.current.onscroll = function(e){

            if(horizontalScrollWrapper.current){

                if(enableVerticalScrollListenerTimeout.current){
                    clearTimeout(enableVerticalScrollListenerTimeout.current)
                }
                
                horizontalScrollPin.current.classList.add(classes.HorizontalScrollController__horizontally_scrolling)
                
                enableVerticalScrollListenerTimeout.current = setTimeout(function(){
                    horizontalScrollPin.current.classList.remove(classes.HorizontalScrollController__horizontally_scrolling)
                    initVerticalScrollListener()
                }, 200)
                
                verticalScrollContainer.current.onscroll = null
            
                var scrollPercentage = 100 * horizontalScrollWrapper.current.scrollLeft / (horizontalScrollWrapper.current.scrollWidth-horizontalScrollWrapper.current.clientWidth)

                document.documentElement.scrollTo(0,((scrollPercentage/100)*(document.documentElement.scrollHeight-document.documentElement.clientHeight-horizontalScrollContainerOffset.current))+horizontalScrollContainerOffset.current); 
            
            }
        }
        
    }

    function onPinSelected(item, index){
        
        gsap.to(horizontalScrollWrapper.current, {duration: 1, scrollTo: {y: 0, x: item.trigger.current.offsetLeft-(50*index)+item.scrolToIndent}});

    }

    return (
    <> 
        <div className={`${classes.HorizontalScrollController} section_wrapper`} ref={horizontalScrollContainer}>
            <div ref={horizontalScrollPin}>
                <div className={`${classes.Articles} section_wrapper`}>
                    <div className={`${classes.HorizontalScrollController__horizontal_scroll_wrapper}`} ref={horizontalScrollWrapper}>
                        <span style={{display:"flex", position:"relative"}} ref={horizontalScrollContentWrapper}>
                            {childrenWithProps}
                            <SectionPins onSectionIndex={onSectionIndex} onPinSelectedCallback={onPinSelected} items={pinItems} controller={horizontalScrollWrapper}/>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </>
    );
}

export default HorizontalScrollContainer