import React, {useRef, useEffect} from "react";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

//////// Styling Imports 
import * as classes from './index.module.scss';

function HorizontalPin({children, controller, leftPinIndex, rightPinIndex, sectionTrigger, onEntered, onExited}) {

    let rightPinScrollTrigger = useRef(null)
    let leftPinScrollTrigger = useRef(null)
    let pinCointainerTriggerElement = useRef(null)
    let pinRef = useRef(null)
    
    useEffect(() => {
        
        const cleanupInstances = {
            rightPinScrollTrigger:rightPinScrollTrigger,
            leftPinScrollTrigger:leftPinScrollTrigger,
        }

        initScrollPin();

        return () => {
            
            onUnmount(cleanupInstances)

        }
    },[])
    //eslint-disable-next-line react-hooks/exhaustive-deps

    function togglePin(){
        let theClasses = [classes.HorizontalPin__pinned, "global_pinned"];
        theClasses.forEach(myClass => pinRef.current.classList.toggle(myClass) )
    }

    function onUnmount(cleanupInstances){
        cleanupInstances.rightPinScrollTrigger.current.kill()
        cleanupInstances.leftPinScrollTrigger.current.kill()
    }

    function initScrollPin(){

        let showMarkers = false;
        let pinnedWidth = parseInt(classes.pinnedWidth)

        rightPinScrollTrigger.current = ScrollTrigger.create({
            trigger: pinCointainerTriggerElement.current,
            scroller:controller.current,
            pin: pinRef.current,
            horizontal: true,
            start:() => `left right-=${(pinnedWidth*rightPinIndex)+pinnedWidth}`,
            end:() => {return (`${(sectionTrigger.current ? sectionTrigger.current.clientWidth-window.innerWidth+sectionTrigger.current.offsetLeft-(pinnedWidth*leftPinIndex) : "")} right`)},
            pinSpacing:false,
            markers:showMarkers,
            onEnterBack:() => {
                onEntered();
                togglePin();
            },
            onLeave:() => {
                onExited();
                togglePin();
            },
            onEnter: togglePin,
            onLeaveBack: togglePin
        })

        leftPinScrollTrigger.current = ScrollTrigger.create({
            trigger: sectionTrigger.current,
            scroller:controller.current,
            pin: pinRef.current,
            horizontal: true,
            start:() => `right-=${(pinnedWidth+(pinnedWidth*leftPinIndex)+165)} left`,
            end:"max",
            pinSpacing:false,
            markers:showMarkers,
            onEnterBack:togglePin,
            onLeave:togglePin,
            onEnter: togglePin,
            onLeaveBack: togglePin
        })
        
    }
    
    return (

        <div className={classes.HorizontalPin} style={{position:"absolute", top:"0px", bottom:"0px", left:`calc((100vw - 15px))`, zIndex:10}}>
            <div className={classes.HorizontalPin__defaults} style={{position:"absolute", height:"100%"}} ref={pinRef}>
                {children}
            </div>
        </div>

    )

}

export default HorizontalPin