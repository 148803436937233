//////// Package Imports 
import React, {useEffect, useRef, useState} from "react"
import Lottie from 'react-lottie';
import {useMediaQuery} from 'react-responsive';
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import ScrollToPlugin from "gsap/ScrollToPlugin"

//////// Helper Imports 
import useDocumentHeight from '../../../helpers/useDocumentHeight'
import { trackCustomEvent } from "../../../helpers/tracking"

//////// Component Imports 
import BackgroundImage from "../../../sharedComponents/BackgroundImage/BackgroundImage";
import Cards from "../../../sharedComponents/Cards/Cards"

//////// Data Imports 
import GlobalData from '../../../data/global';

//////// Styling Imports 
import * as classes from './Trailblazers.module.scss';

//////// Horizontal Controller
import HorizontalPin from "../HorizontalScrollController/HorizontalPin";

//////// Lottie Imports 
import animationData from '../../../lotties/ArrowPink.json';

//////// Image Imports 
const contentImages = require.context('../../../images/HeroImages/', true, /.*\.(gif|png|jpe?g|svg)$/i);

function Trailblazers({horizontalScrollWrapper, content, leftPinIndex, rightPinIndex, contentContainer}) {

    function onArrowClick(){
        let trackObject = {
            category: GlobalData.tracking.gaCategory,
            action: "Click",
            label: "Trailblazer arrow"
        }
        trackCustomEvent(trackObject);
    }

    let data = content.content;
    gsap.registerPlugin(ScrollTrigger);
    gsap.registerPlugin(ScrollToPlugin);
    let lottieContainer = useRef(null);
    let docHeight = useDocumentHeight();
    let pageContainer = useRef(null);
    let [scrollDepth, setScrollDepth] = useState(null)

    const isTablet = useMediaQuery({
        query: `(min-width: ${classes.screenMd})`
    })

    const isDesktop = useMediaQuery({
        query: `(min-width: ${classes.screenLg})`
    })
  
    const dynamicImage = {
        loop: false,
        autoplay: true, 
        animationData: animationData,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice'
        }
    };

    let [shouldArrowStop, setShouldArrowStop] = useState(true)

    useEffect(() =>{

        let scroll = pageContainer.current.clientHeight;
  
        if(isDesktop)
            scroll = pageContainer.current.clientWidth;
            setScrollDepth(scroll)
            
    }, [isDesktop])
  

    useEffect(() => {

        lottieContainer.current = ScrollTrigger.create({
            trigger: lottieContainer.current,
            start:`top 95%`,
            end:() => `max`,
            onEnter:() => {setShouldArrowStop(false)},
        })

    }, [])

    useEffect(() => {
        ScrollTrigger.refresh(true);
    },[docHeight])

    function scrollToComponent(){

        let offsetTopPosition = contentContainer.current.offsetTop - 49;
        
        if(isTablet)
            offsetTopPosition = contentContainer.current.offsetTop - 82;

        if(isDesktop){
            gsap.to(window.HORIZONTAL_SCROLL_WRAPPER, {duration: 1, scrollTo: {x: window.innerWidth}});
        }
        else{
            gsap.to(window, {duration: 1, scrollTo: {y: scrollDepth + offsetTopPosition, x: 0}});
        }

        onArrowClick()
    }

    return (
        <div className={`${classes.Trailblazers}`} style={{backgroundColor: data.backgroundColor, color: data.textColor}}>
            <div ref={pageContainer} className={`${classes.Trailblazers__title_container}`} style={{backgroundColor: data.backgroundColor}}>
                <BackgroundImage backgroundContent={data.backgroundImage} imagePath={contentImages} backgroundPosition={data.backgroundImagePosition} />
                <div className={`${classes.Trailblazers__content}`}>
                    <h1 className={`NationalCondensed-black`} dangerouslySetInnerHTML={{__html:data.title}}></h1>
                    <div ref={lottieContainer} className={`${classes.Trailblazers__content__lottie_container}`} onClick={() => scrollToComponent()}>
                        <Lottie 
                            options={dynamicImage}
                            isStopped={shouldArrowStop}
                        />
                    </div>
                </div>
            </div>
            <div className={`${classes.Trailblazers__cards_container}`} style={{backgroundColor: data.backgroundColor}}>
                <Cards content={data} />
            </div>
        </div>
    )
}

export default Trailblazers