//////// Package Imports 
import React from "react";
import MediaQuery from 'react-responsive';

//////// Helper Imports 
import { getFileName } from '../../helpers/FileCatching';
import { trackCustomEvent } from "../../helpers/tracking"


//////// Data Imports 
import GlobalData from '../../data/global';

//////// Styling Imports 
import * as classes from './Cards.module.scss';

//////// Image Imports 
const contentImages = require.context('../../images/CardImages/TitleImages/', true, /.*\.(gif|png|jpe?g|svg)$/i);
const logoImages = require.context('../../images/CardImages/Icons/', true, /.*\.(gif|png|jpe?g|svg)$/i);

function Cards({content}) {

    function onArticleClick(title){
        let trackObject = {
            category: GlobalData.tracking.gaCategory,
            action: "Click",
            label: "Card " + title
        }
        trackCustomEvent(trackObject)
    }

    return (
        
        <div className={`${classes.Cards}`}>
            <MediaQuery maxWidth={classes.screenLgMax}>
                {content.articles.map( (card,i) => {
                    return (
                    <div className={`${classes.Cards__card} ${(card.cardPosition.tablet === 'right' ? classes.Cards__card_right: classes.Cards__card_left)}`} key={i} style={{backgroundColor: content.cardBackgroundColor, color:content.cardTextColor}}>
                        <a href={card.url} style={{color:content.cardTextColor}} onClick={() => { onArticleClick(card.title)}}>
                            {(card.titleImage !== "" ? 
                                <div className={`${classes.Cards__card__image_container}`}>
                                    <img alt={card.imageAlt} src={getFileName(contentImages,card.titleImage).default}/>
                                </div>
                            :
                                ""
                            )}
                        </a>
                        <div className={`${classes.Cards__card__content_container} ${(card.svg.imagePath !== "" ? classes.Cards__card__content_container__svg_container : "")}`}>
                            <div>
                                {(card.svg.imagePath !== "" ? 
                                    <a href={card.url} style={{color:content.cardTextColor}} onClick={() => { onArticleClick(card.title)}}>
                                        <div className={`${classes.Cards__card__svg_container} svg_containier__${card.svg.position}`}>
                                            <img alt={card.imageAlt} src={getFileName(contentImages,card.svg.imagePath).default}/>
                                            <h3 className={`NationalCondensed-bold`} dangerouslySetInnerHTML={{__html:card.title}}></h3>
                                        </div>
                                    </a>
                                :
                                    <a href={card.url} style={{color:content.cardTextColor}} onClick={() => { onArticleClick(card.title)}}>
                                        <h3 className={`NationalCondensed-bold`} dangerouslySetInnerHTML={{__html:card.title}}></h3>
                                    </a>
                                )}
                                
                                <p className={`CuriousSans-bold`} dangerouslySetInnerHTML={{__html:card.copy}}></p>
                            </div>
                            <div className={`${classes.Cards__card__cta_container}`}>
                                <div className={`${classes.Cards__card__location_container}`}>
                                    <img alt={"Location pin"} src={getFileName(logoImages,content.locationPin).default}/>
                                    <p className={`CuriousSans-bold`} dangerouslySetInnerHTML={{__html:card.location}}></p>
                                </div>
                                <a href={card.url} onClick={() => { onArticleClick(card.title)}}>
                                    <img className={`${classes.Cards__card__go_to_article}`} alt={"Go to article"} src={getFileName(logoImages,content.urlIcon).default}/>
                                </a>
                            </div>
                        </div>
                    </div>
                    )
                })}
            </MediaQuery>
            <MediaQuery minWidth={classes.screenLg}>
                {content.articles.map( (card,i) => {
                    return (
                    <div className={`${classes.Cards__card} ${(card.cardPosition.tablet === 'right' ? classes.Cards__card_right: classes.Cards__card_left)}`} key={i} style={{backgroundColor: content.cardBackgroundColor, color:content.cardTextColor, marginTop:card.cardPosition.desktop}}>
                        <a href={card.url} style={{color:content.cardTextColor}} onClick={() => { onArticleClick(card.title)}}>
                            {(card.titleImage !== "" ? 
                                <div className={`${classes.Cards__card__image_container}`}>
                                    <img alt={card.imageAlt} src={getFileName(contentImages,card.titleImage).default}/>
                                </div>
                            :
                                ""
                            )}
                        </a>
                        <div className={`${classes.Cards__card__content_container} ${(card.svg.imagePath !== "" ? classes.Cards__card__content_container__svg_container : "")}`}>
                            <div>
                                {(card.svg.imagePath !== "" ? 
                                    <a href={card.url} style={{color:content.cardTextColor}} onClick={() => { onArticleClick(card.title)}}>
                                        <div className={`${classes.Cards__card__svg_container} svg_containier__${card.svg.position}`}>
                                            <img alt={card.imageAlt} src={getFileName(contentImages,card.svg.imagePath).default}/>
                                            <h3 className={`NationalCondensed-bold`} dangerouslySetInnerHTML={{__html:card.title}}></h3>
                                        </div>
                                    </a>
                                :
                                    <a href={card.url} style={{color:content.cardTextColor}} onClick={() => { onArticleClick(card.title)}}>
                                        <h3 className={`NationalCondensed-bold`} dangerouslySetInnerHTML={{__html:card.title}}></h3>
                                    </a>
                                )}
                                
                                <p className={`CuriousSans-bold`} dangerouslySetInnerHTML={{__html:card.copy}}></p>
                            </div>
                            <div className={`${classes.Cards__card__cta_container}`}>
                                <div className={`${classes.Cards__card__location_container}`}>
                                    <img alt={"Location pin"} src={getFileName(logoImages,content.locationPin).default}/>
                                    <p className={`CuriousSans-bold`} dangerouslySetInnerHTML={{__html:card.location}}></p>
                                </div>
                                <a href={card.url} onClick={() => { onArticleClick(card.title)}}>
                                    <img className={`${classes.Cards__card__go_to_article}`} alt={"Go to article"} src={getFileName(logoImages,content.urlIcon).default}/> 
                                </a>
                            </div>
                        </div>
                    </div>
                    )
                })}
            </MediaQuery>
            <MediaQuery maxWidth={classes.screenLgMax}>
                <div className={`${classes.Cards__card} ${(content.quote.cardPosition.tablet === 'right' ? classes.Cards__card_right: classes.Cards__card_left)}`} style={{backgroundColor: content.cardBackgroundColor, color:content.cardTextColor}}>
                    <div className={`${classes.Cards__card__image_container}`}>
                        <img alt={content.quote.imgAlt} src={getFileName(contentImages,content.quote.image).default}/>
                    </div>
                    <div className={`${classes.Cards__card__content_container} ${classes.Cards__card__content_container_quote}`}>
                        <h3 className={`PalmerLake`} style={{borderBottom: "none" }}>"<span dangerouslySetInnerHTML={{__html:content.quote.copy}}></span>"</h3>
                        <p className={`NationalCondensed-bold`} dangerouslySetInnerHTML={{__html:content.quote.name}}></p>
                    </div>
                </div>
            </MediaQuery>
            <MediaQuery minWidth={classes.screenLg}>
                <div className={`${classes.Cards__card} ${(content.quote.cardPosition.tablet === 'right' ? classes.Cards__card_right: classes.Cards__card_left)}`} style={{backgroundColor: content.cardBackgroundColor, color:content.cardTextColor, marginTop:content.quote.cardPosition.desktop}}>
                    <div className={`${classes.Cards__card__image_container}`}>
                        <img alt={content.quote.imgAlt} src={getFileName(contentImages,content.quote.image).default}/>
                    </div>
                    <div className={`${classes.Cards__card__content_container} ${classes.Cards__card__content_container_quote}`}>
                        <h3 className={`PalmerLake`} style={{borderBottom: "none" }}>"<span dangerouslySetInnerHTML={{__html:content.quote.copy}}></span>"</h3>
                        <p className={`NationalCondensed-bold`} dangerouslySetInnerHTML={{__html:content.quote.name}}></p>
                    </div>
                </div>
            </MediaQuery>
        </div>
    )
}

export default Cards