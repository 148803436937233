//////// Package Imports 
import React, {useRef, useEffect} from "react";

//////// Component Imports 
import HorizontalPin from "../HorizontalPin";

//////// Helper Imports 
import { trackCustomEvent } from "../../../../helpers/tracking"

//////// Data Imports 
import GlobalData from '../../../../data/global';

//////// Styling Imports 
import * as classes from './index.module.scss';

function SectionPins({controller, items, onPinSelectedCallback, onSectionIndex}) {

    let pinContent = useRef();

    function onTabClick(title){
        let tabTitle = title.replace(/(<([^>]+)>)/gi, " "); 

        let trackObject = {
            category: GlobalData.tracking.gaCategory,
            action: "Click",
            label: tabTitle
        }
        trackCustomEvent(trackObject)

        console.log(trackObject.label)
    }

    function toggle(){

    }
    
    return (

        <>
            {items.map((item, index) => {
                
                return (
                        <HorizontalPin onExited={() => {onSectionIndex(index+1)}} onEntered={() => {onSectionIndex(index)}} sectionTrigger={item.trigger} key={index} controller={controller} leftPinIndex={index} rightPinIndex={(items.length-1) - index}>
                            <div 
                                className={classes.SectionPins__pin}
                                onClick={() => {onPinSelectedCallback(item, index); onTabClick(item.title)}}
                                style={
                                    {
                                        backgroundColor:item.backgroundColour,
                                        color:item.fontColor
                                    }
                                }
                                >
                                <span>
                                    <h1 className={`NationalCondensed-black`}>{item.title.replace(/(<([^>]+)>)/gi, " ")}</h1>
                                </span>
                            </div>
                        </HorizontalPin>
                        )
            })}
        </>

    )

}

export default SectionPins