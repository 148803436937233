//////// Package Imports 
import React, {useEffect, useRef, useState} from "react"
import MediaQuery from 'react-responsive';
import Lottie from 'react-lottie';

/////////Greensock imports
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import SplitText from "gsap/dist/SplitText";
import ScrollToPlugin from "gsap/ScrollToPlugin"

//////// Helper Imports 
import { getFileName } from '../../helpers/FileCatching';
import { trackCustomEvent } from "../../helpers/tracking"

//////// Lottie Imports 
import animationData from '../../lotties/ArrowOrange.json';

//////// Data Imports 
import GlobalData from '../../data/global';

//////// Styling Imports 
import * as classes from './Hero.module.scss';

//////// Image Imports 
const contentImages = require.context('../../images/HeroImages/', true, /.*\.(gif|png|jpe?g|svg)$/i);
const logoImages = require.context('../../images/Icons/', true, /.*\.(gif|png|jpe?g|svg)$/i);

function Hero({content, onEnter}) {

    function onArrowClick(){
        let trackObject = {
            category: GlobalData.tracking.gaCategory,
            action: "Click",
            label: "Hero Arrow"
        }
        trackCustomEvent(trackObject);
    }

    let data = content.content;
    gsap.registerPlugin(ScrollTrigger);
    gsap.registerPlugin(SplitText);
    gsap.registerPlugin(ScrollToPlugin);

    let sectionScrollTriggerRef = useRef(null);
    let sectionContainerRef = useRef(null);
    let heading = useRef(null);
    let subHeading = useRef(null);
    let lottieAnim = useRef(null);

    
    const dynamicImage = {
        loop: false,
        autoplay: false, 
        animationData: animationData,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice'
        }
    };
    
    let [shouldArrowStop, setShouldArrowStop] = useState(true)

    let arrowComponent = <Lottie 
                        options={dynamicImage}
                        isStopped={shouldArrowStop}
                        
                    />

    useEffect(() => {

        sectionScrollTriggerRef.current = ScrollTrigger.create({
            trigger: sectionContainerRef.current,
            start:`-200 0`,
            end:() => `bottom-=${classes.menuHeightDT} top`,
            onEnter:() => {onEnter(0)},
            onEnterBack:() => {onEnter(0)}
        })

    }, [])

    useEffect(() => {

       heroTimeline()

    }, [])

    function heroTimeline(){
        
        let splitComponentCopy = new SplitText(heading.current);
        const scrollComponentCopy = gsap.utils.toArray(splitComponentCopy.lines);

        const tl = gsap.timeline({});

        tl.set(sectionContainerRef.current, {opacity: 1});

        scrollComponentCopy.forEach((div, i) => {
            tl.from(div, {
                autoAlpha: 0,
                x: "-100%"
            });
        });

        tl.from(subHeading.current, {
            autoAlpha: 0,
            x: "-100%"
        });

        tl.add( function(){setShouldArrowStop(false)})
        
    }

    function scrollToComponent(){
        let pageHeight = sectionContainerRef.current.clientHeight;
        gsap.to(window, {duration: 1, scrollTo: {y: pageHeight, x: 0}});
        onArrowClick();
    }

    return (
        <div ref={sectionContainerRef} className={`${classes.Hero} section_wrapper`} style={{backgroundColor: data.backgroundColor, color: data.textColor, opacity:0}}>
            <div className={`${classes.Hero__BackgroundImage}`} >
                <MediaQuery maxWidth={classes.screenMdMax}>
                    <div className={`${classes.Hero__BackgroundImage__wrapper}`} style={{backgroundImage: `url(${getFileName(contentImages,data.backgroundImage.mobile).default})`}}></div>
                </MediaQuery>
                <MediaQuery minWidth={classes.screenMd} maxWidth={classes.screenLgMax}>
                    <div className={`${classes.Hero__BackgroundImage__wrapper}`} style={{backgroundImage: `url(${getFileName(contentImages,data.backgroundImage.tablet).default})`}}></div>
                </MediaQuery>
                <MediaQuery minWidth={classes.screenLg}>
                    <div className={`${classes.Hero__BackgroundImage__wrapper}`} style={{backgroundImage: `url(${getFileName(contentImages,data.backgroundImage.desktop).default})`}}></div>
                </MediaQuery>
            </div>
            <div className={`${classes.Hero__content}`}>
                <h1 ref={heading} className={'NationalCondensed-black'} dangerouslySetInnerHTML={{__html:data.title}}></h1>
                <MediaQuery maxWidth={classes.screenLgMax}>
                    <h2 ref={subHeading} className={`CuriousSans-bold`} dangerouslySetInnerHTML={{__html:data.subTitle}}></h2>
                </MediaQuery>
                <MediaQuery minWidth={classes.screenLg}>
                    <div className={`${classes.Hero__content__biline_wrapper}`}>
                        <h2 ref={subHeading} className={`CuriousSans-bold`} dangerouslySetInnerHTML={{__html:data.subTitle}}></h2>
                        <div onClick={() => scrollToComponent()} ref={lottieAnim} className={`${classes.Hero__content__lottie_container}`}>
                            {arrowComponent}
                        </div>
                    </div>
                </MediaQuery>
                
            </div>
            <MediaQuery maxWidth={classes.screenLgMax}>
                <div onClick={() => scrollToComponent()} ref={lottieAnim} className={`${classes.Hero__content__lottie_container}`}>
                    {arrowComponent}
                </div>
            </MediaQuery>
        </div>
    )
}

export default Hero