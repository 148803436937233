// extracted by mini-css-extract-plugin
export var Trailblazers = "Trailblazers-module--Trailblazers--p8BWF";
export var Trailblazers__cards_container = "Trailblazers-module--Trailblazers__cards_container--EcgA9";
export var Trailblazers__content = "Trailblazers-module--Trailblazers__content--dotJw";
export var Trailblazers__content__lottie_container = "Trailblazers-module--Trailblazers__content__lottie_container--1Pkw4";
export var Trailblazers__tab_container = "Trailblazers-module--Trailblazers__tab_container--nef9A";
export var Trailblazers__title_container = "Trailblazers-module--Trailblazers__title_container--VogKP";
export var screenLg = "992px";
export var screenLgMax = "991px";
export var screenMd = "768px";
export var screenMdMax = "767px";
export var screenSm = "576px";
export var screenSmMax = "575px";
export var screenXl = "1200px";
export var screenXlMax = "1199px";