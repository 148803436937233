////// Package imports 
import React, {useState, useEffect, useRef} from "react"
import { graphql } from "gatsby"

////// Styling imports 
import "../styles/style.scss"

//////// Helper Imports 
import { trackCustomEvent } from "../helpers/tracking"

////// Component imports 
import Header from "../components/Header/Header"
import Hero from "../components/Hero/Hero"
import SubHero from "../components/SubHero/SubHero"
import Articles from "../components/Articles/Articles"

//////// Data Imports
import GlobalData from '../data/global.js';

// markup
const IndexPage = ({data}) => {
    
  let Content = data.dataJson.componentData;
  const headerThemes = ["Header_hero_theme","Header_sub_hero_theme","Header_trailblazers_theme","Header_gamechangers_theme","Header_pathfinders_theme","Header_guides_theme","Header_footer_theme"];
  let progress25Tracked = false;
	let progress50Tracked = false;
	let progress75Tracked = false;
	let progress100Tracked = false;
	let pereviousScrollPercentage = 0
  let [activeHeaderThemes, setActiveHeaderThemes] = useState([]);

  function onSectionChange(index){
    let tempList = [...headerThemes]
    tempList.splice(index+1)
    setActiveHeaderThemes(tempList)
  }

  useEffect( () => {
	  window.addEventListener('scroll', onScroll)
	  // eslint-disable-next-line react-hooks/exhaustive-deps
	},[])

  function onScroll(){
    let articleHeight = document.body.scrollHeight;
		let viewPort= window.innerHeight;
		var percentage = ((getCurrentPosition() + viewPort) - 0)/document.documentElement.scrollHeight * 100;
		var minMaxPercentage = Math.min(100, Math.max(pereviousScrollPercentage, percentage));

    if(minMaxPercentage >= 25){
      if(!progress25Tracked) {
    trackScrollProgress(25);
        progress25Tracked = true;				
      }
    }

    if(minMaxPercentage >= 50){
      if(!progress50Tracked) {
    trackScrollProgress(50);
    progress50Tracked = true;				
      }
    }
    
    if(minMaxPercentage >= 75){
      if(!progress75Tracked) {
    trackScrollProgress(75);
        progress75Tracked = true;
      }
    }

    if(minMaxPercentage >= 100){
      if(!progress100Tracked) {
    trackScrollProgress(100);
        progress100Tracked = true;					
      }
    }
    
    pereviousScrollPercentage= minMaxPercentage

	}

  function trackScrollProgress(progress){
		let trackObject = {
			'category': GlobalData.tracking.gaCategory,
			'action': "Scroll depth",
			'label': progress + "%"
		}
		trackCustomEvent(trackObject)
	}

  function getCurrentPosition (){
		return Math.abs(( window.scrollY || document.body.scrollTop || document.documentElement.scrollTop));
	}

  return (
    <>
      <Header headerThemes={activeHeaderThemes} isSticky={true} />
      <Hero onEnter={onSectionChange} content={Content[0]}/>
      <SubHero onEnter={onSectionChange} content={Content[1]}/>
      <Articles onEnter={onSectionChange} trailblazers={Content[2]} gamechangers={Content[3]} pathfinders={Content[4]} guides={Content[5]} credits={Content[6]}/>
    </>
  )
}
export const query = graphql`
  query MyQuery {
    dataJson {
      componentData {
        content {
          backgroundColor
          backgroundImage {
            desktop
            mobile
            tablet
          }
          backgroundImagePosition
          articles {
            copy
            location
            svg {
              imagePath
              position
            }
            title
            titleImage
            url
            imgAlt
            cardPosition {
              desktop
              tablet
            }
          }
          cardBackgroundColor
          cardTextColor
          cards {
            backgroundColor
            copy
            textColor
            title
          }
          locationPin
          quote {
            copy
            image
            name
            imgAlt
            cardPosition {
              desktop
              tablet
            }
          }
          social {
            icon {
              facebook
              linkedIn
              twitter
            }
          }
          subTitle
          textColor
          title
          urlIcon
        }
        section
      }
    }
  }
`

export default IndexPage
