// extracted by mini-css-extract-plugin
export var BackgroundImage = "BackgroundImage-module--BackgroundImage--61EBf";
export var BackgroundImage__left = "BackgroundImage-module--BackgroundImage__left--xXGAa";
export var BackgroundImage__right = "BackgroundImage-module--BackgroundImage__right--RuLe4";
export var BackgroundImage__wrapper = "BackgroundImage-module--BackgroundImage__wrapper--+rmOq";
export var screenLg = "992px";
export var screenLgMax = "991px";
export var screenMd = "768px";
export var screenMdMax = "767px";
export var screenSm = "576px";
export var screenSmMax = "575px";
export var screenXl = "1200px";
export var screenXlMax = "1199px";