// extracted by mini-css-extract-plugin
export var Cards = "Cards-module--Cards--esZgq";
export var Cards__card = "Cards-module--Cards__card--S0x4Q";
export var Cards__card__content_container = "Cards-module--Cards__card__content_container--0NOdt";
export var Cards__card__content_container__svg_container = "Cards-module--Cards__card__content_container__svg_container--+Eauk";
export var Cards__card__content_container_quote = "Cards-module--Cards__card__content_container_quote--3Qdo3";
export var Cards__card__cta_container = "Cards-module--Cards__card__cta_container--YkND9";
export var Cards__card__go_to_article = "Cards-module--Cards__card__go_to_article--TfxuB";
export var Cards__card__image_container = "Cards-module--Cards__card__image_container--eL8+1";
export var Cards__card__location_container = "Cards-module--Cards__card__location_container--jqJI5";
export var Cards__card__svg_container = "Cards-module--Cards__card__svg_container--V07st";
export var Cards__card_left = "Cards-module--Cards__card_left--AMIIl";
export var Cards__card_right = "Cards-module--Cards__card_right--4fj1x";
export var screenLg = "992px";
export var screenLgMax = "991px";
export var screenMd = "768px";
export var screenMdMax = "767px";
export var screenSm = "576px";
export var screenSmMax = "575px";
export var screenXl = "1200px";
export var screenXlMax = "1199px";