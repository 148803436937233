//////// Package Imports 
import React, {useEffect, useRef, useState} from "react"
import Lottie from 'react-lottie';
import {useMediaQuery} from 'react-responsive';

//////// Helper Imports 
import { getFileName } from '../../helpers/FileCatching';
import useDocumentHeight from '../../helpers/useDocumentHeight'
import { trackCustomEvent } from "../../helpers/tracking"

/////////Greensock imports
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import ScrollToPlugin from "gsap/ScrollToPlugin"

//////// Styling Imports 
import * as classes from './SubHero.module.scss';

//////// Lottie Imports 
import animationData from '../../lotties/ArrowPurple.json';

//////// Data Imports 
import GlobalData from '../../data/global';

//////// Image Imports 
const logoImages = require.context('../../images/Icons/', true, /.*\.(gif|png|jpe?g|svg)$/i);

function SubHero({content, onEnter, subHeroRef}) {

    function onArrowClick(){
        let trackObject = {
            category: GlobalData.tracking.gaCategory,
            action: "Click",
            label: "Sub Hero Arrow"
        }
        trackCustomEvent(trackObject);
    }

    let data = content.content;
    gsap.registerPlugin(ScrollTrigger);
    gsap.registerPlugin(ScrollToPlugin);

    let sectionScrollTriggerRef = useRef(null);
    let sectionContainerRef = useRef(null)
    let lottieContainer = useRef(null);
    let docHeight = useDocumentHeight();
    let [menuHeight, setMenuHeight] = useState();
    let pageContainer = useRef(null);

    const isTablet = useMediaQuery({
        query: `(min-width: ${classes.screenMd})`
    })

    const isDesktop = useMediaQuery({
        query: `(min-width: ${classes.screenLg})`
    })

    const dynamicImage = {
        loop: false,
        autoplay: true, 
        animationData: animationData,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice'
        }
    };

    let [shouldArrowStop, setShouldArrowStop] = useState(true)

    useEffect(() => {

        sectionScrollTriggerRef.current = ScrollTrigger.create({
            trigger: sectionContainerRef.current,
            start:`top ${classes.menuHeightDT}`,
            end:() => `bottom-=${classes.menuHeightDT} top`,
            onEnter:() => {onEnter(1)},
            onEnterBack:() => {onEnter(1)}
        })

    }, [])

    useEffect(() => {

        lottieContainer.current = ScrollTrigger.create({
            trigger: lottieContainer.current,
            start:`top 95%`,
            end:() => `bottom-=${classes.menuHeightDT} top`,
            onEnter:() => {setShouldArrowStop(false)},
        })

    }, [])

    useEffect(() => {
        ScrollTrigger.refresh(true);
        
    },[docHeight])
  
    function scrollToComponent(){
        let pageHeight = pageContainer.current.clientHeight;
        let offsetTopPosition = pageContainer.current.offsetTop - 49;
        
        if(isTablet)
            offsetTopPosition = pageContainer.current.offsetTop - 82;

        if(isDesktop)
            offsetTopPosition = pageContainer.current.offsetTop - 64;

        gsap.to(window, {duration: 1, scrollTo: {y: pageHeight + offsetTopPosition, x: 0}});

        onArrowClick();
    }

    return (
        <div ref={pageContainer} className={`${classes.SubHero} section_wrapper`} style={{backgroundColor: data.backgroundColor}}>
            <div ref={sectionContainerRef} className={`${classes.SubHero__content}`}>
                <div ref={subHeroRef} className={`${classes.SubHero__wrapper}`}>
                {data.cards.map( (card,i) => {
                    return (
                        <div className={`${classes.SubHero__card}`} key={i} style={{backgroundColor: card.backgroundColor, color:card.textColor}}>
                            {(card.title === "" &&  card.copy !== "" ? 
                                <div className={`${classes.SubHero__card__copy} ${classes.SubHero__card__desc}`}>
                                    <p className={`CuriousSans-bold`} dangerouslySetInnerHTML={{__html:card.copy}}></p>
                                </div>
                                :
                                <div className={`${classes.SubHero__card__copy} ${classes.SubHero__card__header}`}>
                                    <h3 className={`NationalCondensed-bold`} dangerouslySetInnerHTML={{__html:card.title}} ></h3>
                                    {(card.copy !== "" ? 
                                        <p className={`CuriousSans-bold`} dangerouslySetInnerHTML={{__html:card.copy}}></p>
                                    :
                                        ""
                                    )}
                                    <span ref={lottieContainer} onClick={() => scrollToComponent()}>
                                        <Lottie 
                                            options={dynamicImage}
                                            isStopped={shouldArrowStop}
                                        />
                                    </span>
                                </div>
                            )}
                        </div>
                    )
                })}
                </div>
            </div>
        </div>
    )
}

export default SubHero