//////// Package Imports 
import React, {useRef, useEffect, useState} from "react";
import MediaQuery from 'react-responsive';

//////// Component Imports 
import Trailblazers from "./Trailblazers/Trailblazers";
import Gamechangers from "./Gamechangers/Gamechangers";
import Pathfinders from "./Pathfinders/Pathfinders"
import Guides from "./Guides/Guides";
import Credits from "./Credits/Credits";

//////// Controller imports
import HorizontalScrollController from "./HorizontalScrollController";
import VerticalArticleTriggerController from "./VerticalArticleTriggerController";

//////// Helpers
import useWindowSize from "../../helpers/useWindowSize";

//////// Styling Imports 
import * as classes from './Articles.module.scss';


function Articles({trailblazers, gamechangers, pathfinders, guides, credits, onEnter}) {

    let articleContainer = useRef(null);
    let [articleSpacing, setArticleSpacing] = useState(0);
    let [windowWidth] = useWindowSize();

    let trailblazersPinTriggerRef = useRef(null)
    let gamechangersPinTriggerRef = useRef(null)
    let pathfindersPinTriggerRef = useRef(null)
    let guidesPinTriggerRef = useRef(null)
    let articlesContainer = useRef(null)

    let pinItems = [
        {
            title:trailblazers.content.title,
            fontColor:trailblazers.content.textColor,
            backgroundColour:classes.trailBlazersColour,
            trigger:trailblazersPinTriggerRef,
            scrolToIndent:0
        },
        {
            title:gamechangers.content.title,
            fontColor:gamechangers.content.textColor,
            backgroundColour:classes.gameChangersColour,
            trigger:gamechangersPinTriggerRef,
            scrolToIndent:0
        },
        {
            title:pathfinders.content.title,
            fontColor:pathfinders.content.textColor,
            backgroundColour:classes.pathFindersColour,
            trigger:pathfindersPinTriggerRef,
            scrolToIndent:300
        },
        {
            title:guides.content.title,
            fontColor:guides.content.textColor,
            backgroundColour:classes.guidesColour,
            trigger:guidesPinTriggerRef,
            scrolToIndent:0
        }
    ]

    function onHorizontalSectionEntered(index){
        onEnter(index+2)
    }

    useEffect(() => {
        if(articleContainer.current){
            setArticleSpacing(articleContainer.current.clientWidth)
        }
    }, [windowWidth])

    return (
        <>
            <MediaQuery maxWidth={classes.screenLgMax}>
                <div ref={articlesContainer} className={`${classes.Articles} section_wrapper`}>
                    <div>
                        <div className={`${classes.Articles__horizontal_scroll_wrapper}`}>
                            <VerticalArticleTriggerController articleIndex={0} onEntered={onHorizontalSectionEntered}>
                                <Trailblazers content={trailblazers} contentContainer={articleContainer}/>
                            </VerticalArticleTriggerController>
                            <VerticalArticleTriggerController articleIndex={1} onEntered={onHorizontalSectionEntered}>
                                <Gamechangers content={gamechangers}/>
                            </VerticalArticleTriggerController>
                            <VerticalArticleTriggerController articleIndex={2} onEntered={onHorizontalSectionEntered}>
                                <Pathfinders content={pathfinders}/>
                            </VerticalArticleTriggerController>
                            <VerticalArticleTriggerController articleIndex={3} onEntered={onHorizontalSectionEntered}>
                                <Guides content={guides}/>
                            </VerticalArticleTriggerController>
                            <VerticalArticleTriggerController articleIndex={4} onEntered={onHorizontalSectionEntered}>
                                <Credits type={"vertical"} content={credits}/>
                            </VerticalArticleTriggerController>
                        </div>
                    </div>
                </div>
            </MediaQuery>
            <MediaQuery minWidth={classes.screenLg}>
                <div ref={articlesContainer} style={{marginBottom:articleSpacing}} className={`${classes.Articles} section_wrapper`}>
                    <HorizontalScrollController onSectionIndex={onHorizontalSectionEntered} pinItems={pinItems}>   
                        <span style={{display:"flex", position:"absolute",width:"100%"}} ref={articleContainer}></span>
                        <span ref={trailblazersPinTriggerRef}><Trailblazers content={trailblazers} contentContainer={articlesContainer}/></span>
                        <span ref={gamechangersPinTriggerRef}><Gamechangers content={gamechangers}/></span>
                        <span ref={pathfindersPinTriggerRef}><Pathfinders content={pathfinders}/></span>
                        <span ref={guidesPinTriggerRef}><Guides content={guides}/></span>
                        <Credits type={"horizontal"} content={credits}/>
                    </HorizontalScrollController>
                </div>
            </MediaQuery>
        </>
    )
}

export default Articles